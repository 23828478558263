export default {
  // BASE
  CHANGE_LANGUAGE: '@@CHANGE_LANGUAGE',
  SET_USER_GEOLOCATION: '@@SET_USER_GEOLOCATION',
  GET_DICTIONARY_ERROR: '@@GET_DICTIONARY_ERROR',
  SET_DICTIONARY_ERROR: '@@SET_DICTIONARY_ERROR',
  SET_SEARCH_SUGGESTIONS_OPEN: '@@SET_SEARCH_SUGGESTIONS_OPEN',
  SET_SELECTED_SETTLEMENT: '@@SET_SELECTED_SETTLEMENT',
  SET_MOBILE_SEARCH_OPEN: '@@SET_MOBILE_SEARCH_OPEN',
  SET_DICTIONARY: '@@SET_DICTIONARY',
  SET_SELECTED_PROVINCE: '@@SET_SELECTED_PROVINCE',
  SET_URL_STRUCTURE: '@@SET_URL_STRUCTURE',
  FETCH_DICTIONARY: '@@FETCH_DICTIONARY',
  SET_LANGUAGE: '@@SET_LANGUAGE',
  TOGGLE_CATEGORIES_MENU: '@@TOGGLE_CATEGORIES_MENU',
  SET_PAGE_DATA: '@@SET_PAGE_DATA',
  SET_DEVICE_PARAMS: '@@SET_DEVICE_PARAMS',
  SOMETHING_WENT_WRONG: '@@SOMETHING_WENT_WRONG',
  SET_ASIDE_MENU_CLOSEST: '@@SET_ASIDE_MENU_CLOSEST',
  ADD_TOAST: '@@ADD_TOAST',
  REMOVE_TOAST: '@@REMOVE_TOAST',
  SET_HEADER_RECT: '@@SET_HEADER_RECT',
  SET_TOP_MY_SJ_BAR: '@@SET_TOP_MY_SJ_BAR',
  SET_ACCOUNT_CONFIGS: '@@SET_ACCOUNT_CONFIGS',
  SET_PROFILE_CONFIGS: '@@SET_PROFILE_CONFIGS',
  GET_PROFILE_CONFIGS: '@@GET_PROFILE_CONFIGS',
  GET_ACCOUNT_CONFIGS: '@@GET_ACCOUNT_CONFIGS',
  DISABLE_ERROR_MESSAGE: '@@DISABLE_ERROR_MESSAGE',
  SET_MEDIA_CONFIGS: '@@SET_MEDIA_CONFIGS',
  GET_MEDIA_CONFIGS: '@@GET_MEDIA_CONFIGS',
  FETCH_GEOGRAPHY_CONFIGS: '@@FETCH_GEOGRAPHY_CONFIGS',
  SET_GEOGRAPHY_CONFIGS: '@@SET_GEOGRAPHY_CONFIGS',
  SET_ALL_CATEGORY_SERVICES: '@@SET_ALL_CATEGORY_SERVICES',
  FETCH_LOCATIONS: '@@FETCH_LOCATIONS',
  SET_LOCATIONS: '@@SET_LOCATIONS',
  FETCH_POPULAR_CITIES: '@@FETCH_POPULAR_CITIES',
  SET_POPULAR_CITIES: '@@SET_POPULAR_CITIES',

  // ADS
  SET_MY_ADS: '@@SET_MY_ADS',
  NEED_TO_UPDATE_ONLINE_AD: '@@NEED_TO_UPDATE_ONLINE_AD',
  RESET_ADS: '@@RESET_ADS',
  SET_CATEGORIES_WITH_SECTIONS: '@@SET_CATEGORIES_WITH_SECTIONS',
  SET_MY_ADS_AFTER_CHANGE_PAGE: '@@SET_MY_ADS_AFTER_CHANGE_PAGE',
  SET_ADS: '@@SET_ADS',
  SET_MY_AD: '@@SET_MY_AD',
  SAVE_FILTERED_PROFILE_AD: '@@SAVE_FILTERED_PROFILE_AD',
  FILTER_PROFILE_AD_LIST: '@@FILTER_PROFILE_AD_LIST',
  GET_PROFILE_AD: '@@GET_PROFILE_AD',
  TOGGLE_SELECTED_AD: '@@TOGGLE_SELECTED_AD',
  FETCH_PROFILE_ADS: '@@FETCH_PROFILE_ADS',
  GET_ADS_CONFIGS: '@@GET_ADS_CONFIGS',
  SET_PROFILE_ADS: '@@SET_PROFILE_ADS',
  REMOVE_PROFILE_ADS: '@@REMOVE_PROFILE_ADS',
  FETCH_AD_CONFIGS: '@@FETCH_AD_CONFIGS',
  FETCH_CATEGORIES_WITH_SECTIONS: '@@FETCH_CATEGORIES_WITH_SECTIONS',
  SET_AD_CONFIGS: '@@SET_AD_CONFIGS',
  CREATE_AD: '@@CREATE_AD',
  SAVE_AS_DRAFT: '@@SAVE_AS_DRAFT',
  CREATE_ADVERTENTIES: '@@CREATE_ADVERTENTIES',
  FETCH_AD_DRAFT: '@@FETCH_AD_DRAFT',
  SET_AD_DRAFTS: '@@SET_AD_DRAFTS',
  SET_MAIN_VALUES: '@@SET_MAIN_VALUES',
  GET_DRAFT_AND_CATEGORIES: '@@GET_DRAFT_AND_CATEGORIES',
  SAVE_CATEGORIES: '@@SAVE_CATEGORIES',
  SAVE_DATA_STEP_THREE: '@@SAVE_DATA_STEP_THREE',
  GET_STEP_FIVE_VALUES: '@@GET_STEP_FIVE_VALUES',
  SAVE_STEP_FIVE_VALUES: '@@SAVE_STEP_FIVE_VALUES',

  // FETCH_ACCOUNT_ADS: '@@FETCH_ACCOUNT_ADS',
  UPDATE_AD: '@@UPDATE_AD',
  SAVE_CATEGORY: '@@SAVE_CATEGORY',
  SAVE_IMAGES_STEP_FOUR: '@@SAVE_IMAGES_STEP_FOUR',
  SAVE_STEP_FOUR_CHECKED_IMAGES: '@@SAVE_STEP_FOUR_CHECKED_IMAGES',
  DELETE_IMAG_FROM_STEP_FOUR: '@@DELETE_IMAG_FROM_STEP_FOUR',
  GET_STEP_FOUR_IMAGES: '@@GET_STEP_FOUR_IMAGES',
  RESET_PROFILE_ADD_VALUES: '@@RESET_PROFILE_ADD_VALUES',
  RESET_PROFILE_ADD_FROM_STATE: '@@RESET_PROFILE_ADD_FROM_STATE',
  GET_PROFILE_ADS_LISTING: '@@GET_PROFILE_ADS_LISTING',
  GET_PROFILE_AD_LIST: '@@GET_PROFILE_AD_LIST',

  // AUTH
  USER_LOGOUT: '@@USER_LOGOUT',
  SET_USER_DATA: '@@SET_USER_DATA',
  SET_ACCOUNT_PROFILES: '@@SET_ACCOUNT_PROFILES',
  SET_LOGIN_BY_DIALOG: '@@SET_LOGIN_BY_DIALOG',
  UPDATE_PROFILE_DATA: '@@UPDATE_PROFILE_DATA',
  DELETE_PROFILE: '@@DELETE_PROFILE',
  CREATE_PROFILE: '@@CREATE_PROFILE',
  FETCHING_PROFILES: '@@FETCHING_PROFILES',
  CHANGE_EMAIL: '@@CHANGE_EMAIL',
  DELETE_ACCOUNT: '@@DELETE_ACCOUNT',
  ADD_P_W: '@@ADD_P_W',
  DELETE_P_W: '@@DELETE_P_W',

  // MESSENGER
  NEED_TO_UPDATE: '@@NEED_TO_UPDATE',
  SET_EMPTY_THREADS: '@@SET_EMPTY_THREADS',
  SET_MESSENGER_CONFIGS: '@@SET_MESSENGER_CONFIGS',
  SET_DISCRETE_COLOR: '@@SET_DISCRETE_COLOR',
  SET_PROFILE_DETAILS_TOGGLE: '@@SET_PROFILE_DETAILS_TOGGLE',
  GET_THREADS: '@@GET_THREADS',
  SET_THREADS: '@@SET_THREADS',
  SET_MSG_SETTINGS: '@@SET_MSG_SETTINGS',
  GET_MSG_SETTINGS: '@@GET_MSG_SETTINGS',
  UPDATE_MSG_SETTINGS: '@@UPDATE_MSG_SETTINGS',
  SET_SEARCH_FILTER: '@@SET_SEARCH_FILTER',
  TOGGLE_THREADS_LOADING: '@@TOGGLE_THREADS_LOADING',
  SET_FILTERS: '@@SET_FILTERS',
  GET_FILTERS: '@@GET_FILTERS',
  UPDATE_FILTERS: '@@UPDATE_FILTERS',
  SET_THREAD_USER: '@@SET_THREAD_USER',
  SET_THREAD_USERS: '@@SET_THREAD_USERS',
  SET_SELECTED_THREAD_ID: '@@SET_SELECTED_THREAD_ID',
  GET_THREAD_MESSAGES: '@@GET_THREAD_MESSAGES',
  SET_THREAD_MESSAGES: '@@SET_THREAD_MESSAGES',
  SET_THREAD_AS_READ: '@@SET_THREAD_AS_READ',
  GET_UNREAD_COUNT: '@@GET_UNREAD_COUNT',
  SET_UNREAD_COUNT: '@@SET_UNREAD_COUNT',
  GET_AD_DETAILS: '@@GET_AD_DETAILS',
  SET_AD_DETAILS: '@@SET_AD_DETAILS',
  GET_AD_DETAILS_PROFILES: '@@GET_AD_DETAILS_PROFILES',
  SET_AD_DETAILS_PROFILES: '@@SET_AD_DETAILS_PROFILES',
  DELETE_OR_REPORT_THREAD: '@@DELETE_OR_REPORT_THREAD',
  TOGGLE_BLOCKING: '@@TOGGLE_BLOCKING',
  RESET_CHAT_STATE: '@@RESET_CHAT_STATE',
  TOGGLE_DISCRETE_MODE: '@@TOGGLE_DISCRETE_MODE',
  SET_THREADS_LIMIT: '@@SET_THREADS_LIMIT',
  SET_NEW_MESSAGE: '@@SET_NEW_MESSAGE',
  SET_MARK_AS_READ: '@@SET_MARK_AS_READ',

  // Notifications
  SET_NOTIFICATIONS: '@@SET_NOTIFICATIONS',
  CRUD_NOTIFICATION: '@@CRUD_NOTIFICATION',
  GET_NOTIFICATIONS: '@@GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_LIST: '@@GET_NOTIFICATIONS_LIST',
  ACTION_NOTIFICATION: '@@ACTION_NOTIFICATION',
  SAVE_NOTIFICATION_LIST: '@@SAVE_NOTIFICATION_LIST',
  UPDATE_NOTIFICATION: '@@UPDATE_NOTIFICATION',

  // Shopping cart
  GET_PAYMENTS_METHODS: '@@GET_PAYMENTS_METHODS',
  GET_CREDITS: 'GET_CREDITS',
  SAVE_CREDITS: 'SAVE_CREDITS',
  SAVE_PAYMENT_METHODS: 'SAVE_PAYMENT_METHODS',
  ACCEPT_COOKIES: 'ACCEPT_COOKIES',
  SET_ACCEPT_COOKIES: '@@SET_ACCEPT_COOKIES',
  ACCEPT_RECOVER: 'ACCEPT_RECOVER',
  SET_ACCEPT_RECOVER: '@@SET_ACCEPT_RECOVER',
};
