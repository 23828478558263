import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import useMemoSelector from 'hooks/useMemoSelector';
import { Provider, useDispatch } from 'react-redux';
import { NextIntlProvider } from 'next-intl';
import 'react-toastify/dist/ReactToastify.css';
import useDevice from 'hooks/use-media-device';
import withRedux from 'next-redux-wrapper';
import jwt_decode from 'jwt-decode';
import { ToastContainer } from 'react-toastify';
import canHoverHook from 'hooks/can-hover';
import useFetchOnRouteChange from 'hooks/useFetchOnRouteChange';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ErrorHandlingToast from 'hoc/ErrorHandlingToast';
import useGlobalStyles from 'static/styles/jss/base/globalStyles';
import useMount from 'hooks/use-mount';
import useSocketIo from 'hooks/useSocketIo';

import {
  TermsAndPrivacy,
  PersonalInformation,
} from 'Components/LegalInformationDialog';
import RecoveredDialog from 'Components/RecoveredDialog';
import ErrorBoundary from 'Components/ErrorBoundary';

import noop from 'utils/noop';
import { ENV, CONSOLE_METHODS_BY_LEVEL } from 'utils/constants';
import { saveToStorage, getFromStorage } from 'utils/persist';
import { Cookie } from 'utils/handlers';
import uuid, { cuid } from 'utils/uuid';
import { ScrollProvider } from 'utils/ScrollProvider';

import { setDictionary } from 'store/actions/base';
import {
  getLanguage,
  getLocations,
  getDictionary,
  getPopularCities,
  getSelectedCategory,
  deviceParams as getDeviceParams,
} from 'store/reselect';
import createStore, { store } from 'store/configureStore';
import {
  setUserData,
  fetchLocations,
  setUrlStructure,
  setDeviceParams,
  getProfileConfigs,
  fetchPopularCities,
  getDictionaryErrors,
  fetchGeographyConfigs,
  setAllCategoryServices,
  fetchCategoriesWithSections,
} from 'store/actions';

import API from 'services/api';

import DefaultLayout from './_layouts/default';

const theme = createTheme({});

const AsideMenu = dynamic(
  () => import('Components/Dumb/AsideMenu').then(module => module.default),
  { ssr: true },
);

const App = ({ Component, pageProps }) => {
  const dispatch = useDispatch();
  const locale = useMemoSelector(getLanguage);
  const dictionary = useMemoSelector(getDictionary);
  const deviceParams = useMemoSelector(getDeviceParams());
  const Layout = Component.Layout || DefaultLayout;
  const { layoutProps = {} } = pageProps;
  const { deviceSize, deviceType, windowWidth, windowHeight } = useDevice();
  const { canHover } = canHoverHook();

  useFetchOnRouteChange();

  useGlobalStyles();

  useSocketIo(store);

  useMount(() => {
    for (const method in console) {
      if (!CONSOLE_METHODS_BY_LEVEL[ENV.logLevel].includes(method)) {
        console[method] = noop;
      }
    }
  });

  useMount(() => {
    if (!Cookie.getCookieByKey('ch')) {
      Cookie.setCookieByKey('ch', uuid());
    }

    if (!getFromStorage('uid')) {
      saveToStorage('uid', cuid());
    }

    dispatch(getProfileConfigs());
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.body.classList[canHover ? 'add' : 'remove'](
        'cant-hover--device',
      );
    }
  }, [canHover]);

  useEffect(() => {
    // should detect for Messenger discrete mode if it is running or not
    if (typeof window !== 'undefined') {
      document.body.classList[
        !layoutProps.waitToDiscreteMode ? 'add' : 'remove'
      ]?.('cant-be-discrete-mode');
    }
  }, [layoutProps.waitToDiscreteMode]);

  useMount(() => {
    dispatch(getDictionaryErrors());
    dispatch(setAllCategoryServices());
    dispatch(fetchCategoriesWithSections());
  });

  useEffect(() => {
    if (
      deviceParams.deviceSize !== deviceSize ||
      deviceParams.deviceType !== deviceType ||
      deviceParams.deviceWidth !== windowWidth ||
      deviceParams.deviceHeight !== windowHeight
    ) {
      dispatch(
        setDeviceParams({
          deviceSize,
          deviceType,
          deviceWidth: windowWidth,
          deviceHeight: windowHeight,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceType, deviceSize, windowWidth, windowHeight, deviceParams]);

  useMount(() => {
    (async () => {
      if (typeof window !== 'undefined') {
        const token = Cookie.getToken || sessionStorage.token;
        if (token) {
          try {
            const userTokenData = jwt_decode(token);
            dispatch(setUserData({ processLogin: true }));
            const userData = await API.getAccount({
              id: sessionStorage.getItem('accountId') || userTokenData.id,
            });
            dispatch(
              setUserData({
                ...userTokenData,
                ...userData.data,
                processLogin: false,
              }),
            );
          } catch (err) {
            console.error(err.message);
          }
        } else {
          dispatch(setUserData({ processLogin: false }));
        }
      }
    })();

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  });

  return (
    <ErrorBoundary>
      <ScrollProvider>
        <NextIntlProvider
          messages={dictionary}
          locale={locale}
          onError={() => {}}
        >
          <Layout {...layoutProps}>
            <CssBaseline />
            {deviceType === 'mobile' && <AsideMenu />}
            <Component {...pageProps} />
          </Layout>
          <ErrorHandlingToast>
            <ToastContainer
              position={
                deviceType === 'desktop' ? 'top-right' : 'bottom-center'
              }
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={true}
              pauseOnHover
            />
          </ErrorHandlingToast>
          <TermsAndPrivacy />
          <PersonalInformation />
          <RecoveredDialog />
        </NextIntlProvider>
      </ScrollProvider>
    </ErrorBoundary>
  );
};

const WithProviders = props => (
  <Provider store={props.store}>
    <ThemeProvider theme={theme}>
      <App {...props} />
    </ThemeProvider>
  </Provider>
);

WithProviders.getInitialProps = async ({ Component, ctx }) => {
  const token = ctx?.req?.cookies?.token;
  const language = ctx?.req?.cookies?.lang || 'nl';
  let pageProps = {};

  const locale = token ? jwt_decode(token).language : language;

  if (!ctx.store?.getState().base.geographyConfigs.country) {
    await new Promise(resolve => {
      ctx.store.dispatch(fetchGeographyConfigs({ cb: resolve }));
    });
  }
  const selectedCategory = getSelectedCategory(
    ctx.store.getState(),
    ctx.query.slug,
  );

  const { category_id, selectedFilters, slug, ...rest } = ctx.query;

  const referenceIdRegexp = /_\d+/;

  const needSkipValidation = slug?.some(path => referenceIdRegexp.test(path));

  if (!needSkipValidation && slug?.length) {
    const { data: urlStructure } = await API.validateUrl({
      slug: slug,
      ...rest,
    });

    ctx.store?.dispatch(setUrlStructure(urlStructure));

    const { category_id: categoryId, label_ids } = urlStructure;

    if (!getLocations(ctx.store.getState()).length) {
      await new Promise(resolve => {
        ctx.store?.dispatch(
          fetchLocations({
            ...(!!categoryId && {
              categoryId: categoryId,
            }),
            ...(!!label_ids?.length && {
              labelId: label_ids[0],
            }),
            cb: resolve,
          }),
        );
      });
    }
  }

  if (!getPopularCities(ctx.store.getState()).length) {
    await new Promise(resolve => {
      ctx.store?.dispatch(
        fetchPopularCities({ categoryId: selectedCategory?.id, cb: resolve }),
      );
    });
  }

  if (typeof window === 'undefined') {
    await new Promise(resolve => {
      if (!ctx.store.getState().ads.categoriesWithSections) {
        ctx.store?.dispatch(setAllCategoryServices());
        ctx.store?.dispatch(fetchCategoriesWithSections());
      }

      ctx.store?.dispatch(setDictionary({ locale, cb: resolve }));
    });

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ ctx });
    }

    return {
      pageProps: {
        ...pageProps,
        locale,
      },
    };
  }

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps({ ctx });
  }

  return {
    pageProps: {
      ...pageProps,
      locale,
    },
  };
};

export default withRedux(createStore)(WithProviders);
