import Types from '../types';

export const changeLanguage = payload => ({
  type: Types.CHANGE_LANGUAGE,
  payload,
});

export const toggleCategoriesMenuMobile = payload => ({
  type: Types.TOGGLE_CATEGORIES_MENU,
  payload,
});

export const setHeaderRect = payload => ({
  type: Types.SET_HEADER_RECT,
  payload,
});

export const setTopMySJBar = payload => ({
  type: Types.SET_TOP_MY_SJ_BAR,
  payload,
});

export const fetchGeographyConfigs = payload => ({
  type: Types.FETCH_GEOGRAPHY_CONFIGS,
  payload,
});

export const setPageData = payload => ({
  type: Types.SET_PAGE_DATA,
  payload,
});

export const fetchLocations = payload => ({
  type: Types.FETCH_LOCATIONS,
  payload,
});

export const fetchPopularCities = payload => ({
  type: Types.FETCH_POPULAR_CITIES,
  payload,
});

export const setDeviceParams = payload => ({
  type: Types.SET_DEVICE_PARAMS,
  payload,
});

export const setErrorMessage = payload => ({
  type: Types.SOMETHING_WENT_WRONG,
  payload,
});

export const setDictionary = payload => ({
  type: Types.SET_DICTIONARY,
  payload,
});

export const toggleErrorDisabling = payload => ({
  type: Types.DISABLE_ERROR_MESSAGE,
  payload,
});

export const getNotifications = payload => ({
  type: Types.GET_NOTIFICATIONS,
  payload,
});

export const getNotificationsList = payload => ({
  type: Types.GET_NOTIFICATIONS_LIST,
  payload,
});

export const crudNotification = payload => ({
  type: Types.CRUD_NOTIFICATION,
  payload,
});

export const getProfileConfigs = payload => ({
  type: Types.GET_PROFILE_CONFIGS,
  payload,
});

export const getAccountConfigs = payload => ({
  type: Types.GET_ACCOUNT_CONFIGS,
  payload,
});

export const getMediaConfigs = payload => ({
  type: Types.GET_MEDIA_CONFIGS,
  payload,
});

export const getDictionaryErrors = payload => ({
  type: Types.GET_DICTIONARY_ERROR,
  payload,
});

export const setSearchSuggestionsOpen = payload => ({
  type: Types.SET_SEARCH_SUGGESTIONS_OPEN,
  payload,
});

export const setSelectedSettlement = payload => ({
  type: Types.SET_SELECTED_SETTLEMENT,
  payload,
});

export const setSelectedProvince = payload => ({
  type: Types.SET_SELECTED_PROVINCE,
  payload,
});

export const setUrlStructure = payload => ({
  type: Types.SET_URL_STRUCTURE,
  payload,
});

export const setMobileSearchOpen = payload => ({
  type: Types.SET_MOBILE_SEARCH_OPEN,
  payload,
});

export const setUserGeolocation = payload => ({
  type: Types.SET_USER_GEOLOCATION,
  payload,
});
